// ui_frontend/templates/ui/src/routes/Resources.jsx
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";

import { IoLogoMarkdown } from "react-icons/io";
// <IoLogoMarkdown /> // Markdown Logo
import { FaExternalLinkAlt } from "react-icons/fa";

import Title from "../components/Title";
import ResourceText from "../sitecontents/ResourceText";

import SASSMixins from "../assets/SASSMixins.jpg";
import ResourcesTopPic from "../assets/ResourcesTopPic.jpg";
import LearnNew from "../assets/LearnNew.jpg";

const Resources = () => {
  return (
    <>
      <Title
        title="Knots & Knowledge | Resources"
        description="Resource Page for Knots & Knowledge, a site for taking up new artistic creative pursuits like woodworking, crochet, drawing, and more. This page has information on how it was made and some other useful links."
        keywords="knots and knowledge, woodworking, wood crafts, website, make website, django, crochet, crafts, programming"
      />
      <div className="extra-space-top-only-image">
        <div>
          <Card className="card-hidden-behind">
            <Image src={ResourcesTopPic} alt="Things you can make" fluid />
          </Card>
        </div>
        <div className="content-wrapper">
          <hr />
          <Row>
            <h1 align="center">Resources</h1>
            <ResourceText />
          </Row>
          <br />
          <hr />
          <Container className="container-center-flex">
            <Card className="card-static-one">
              <Image
                width={600}
                src={SASSMixins}
                alt="A picture of SASS variables sent from the frontend"
                fluid
              />
            </Card>
          </Container>
          <h2 align="center">How the Site was Made</h2>
          <p>
            I made this site with Python, Javascript, HTML, and CSS by way of
            Django, React, SCSS. It was built in Docker Containers and uses
            Postgres on the backend. It also makes use of Tanstack's useQuery
            and useQueries, Redux, React Router Dom, ts Particles, React Icons
            and more. If you like the site and would like one like it, contact
            me. The site also has Markdown Language
            <IoLogoMarkdown /> integrated into it. I do have a boilerplate
            available as well.
          </p>
          <Row>
            <a
              href="https://fortunatecuttlefish.gumroad.com/l/hjrvq"
              target="_blank"
              rel="noopener noreferrer"
            >
              Dockerized Django React SCSS App Boilerplate <FaExternalLinkAlt />
            </a>
          </Row>
          <hr />
        </div>
        <br />
        <div>
          <Card className="card-hidden-behind">
            <Image src={LearnNew} alt="Interstial Stained Glass Image" fluid />
          </Card>
        </div>
      </div>
    </>
  );
};

export default Resources;
