// ui_frontend/templates/ui/src/posts/PostCard.jsx
import { Link } from 'react-router-dom';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Figure from 'react-bootstrap/Figure';
import Row from 'react-bootstrap/Row';
import ReactMarkdown from 'react-markdown';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { GiWyvern, GiVineLeaf } from "react-icons/gi";

import Spinner from '../components/Spinner';
import { getPosts, getPost } from '../datacalls/getPosts';

// <button className="btn-gl">Click Here To Read More</button>
// <button className="btn-green-lavendar">Click Here To Read More</button>
// <button className="two-button"><span className="text">Hover me</span><span>Thanks!</span></button>
// <button className="fill-button">Click Here To Read More</button>
// <button className="two-button">
//   <span className="text">To Read the Story</span>
//   <span>Click Here</span>
// </button>

// post-card

const PostCard = ({post}) => {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <>
    <Link to={`/posts/${post.slug_post}`} className="link-router">
      <Card style={{ width: '15rem' }} className={`post-card card-${post.card_background_type}`}>
        <Card.Img variant="top" src={post.post_image} />
        <Card.Body>
          <Card.Title><h3>{post.title}</h3></Card.Title>
          <Card.Text>
              <p>{post.post_intro.substring(0,200)}...</p>
              <h3>Click this card to read more <GiVineLeaf /> </h3>
          </Card.Text>
        </Card.Body>
      </Card>
    </Link>
    </>
  );
};

export default PostCard;
