// ui_frontend/templates/ui/src/siteContents/ProjectText.jsx
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { getSiteContents, getSiteContent } from '../datacalls/getSiteContent';
import Spinner from '../components/Spinner';
// The following is important information from site contents in relation to
// the contents of this function.
// PROJECT_TEXT = 'project-text'

export default function ProjectText(props) {
  const queryClient = useQueryClient();
  const [selectedSiteContent, setSelectedSiteContent] = useState();
  // const { isLoading, isError, data, error } = useQuery(['project-text', selectedSiteContent], () =>
  //   getSiteContent('project-text'), // using the api slug here
  // );
  const { isPending, isError, data, error } = useQuery({
    queryKey: ['project-text', { selectedSiteContent }],
    queryFn: () => getSiteContent('project-text'), // using the api slug here
  });
  if (isPending) {
    return <div align="center"><Spinner /></div>;
  }
  if (isError) {
    return <p>Error from the project text in site contents: {error.message}</p>;
  }
  let projecttext = data;

  return (
    <>
      <ReactMarkdown>{projecttext.description}</ReactMarkdown>
    </>
  );
}
