// ui_frontend/templates/ui/src/datacalls/getSiteContent.js
import axios from 'axios';

// These Axios calls are used to call site contents in different parts of the site

export async function getSiteContents() {
    const apiUrl = process.env.BACK_TEST_URL
    const { data } = await axios.get(`${apiUrl}/site-contents/`);
    let sitecontents = data;
    return sitecontents;
}

export async function getSiteContent(selectedSiteContent) {
    const apiUrl = process.env.BACK_TEST_URL
    const { data } = await axios.get(`${apiUrl}/site-contents/${selectedSiteContent}`);
    let sitecontent = data;
    return sitecontent;
}
