// ui_frontend/templates/ui/src/siteContents/ContactText.jsx
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { getSiteContents, getSiteContent } from '../datacalls/getSiteContent';
import Spinner from '../components/Spinner';
// The following is important information from site contents in relation to
// the contents of this function.
// CONTACT_TEXT = 'contact-text'

export default function ContactText(props) {
  const queryClient = useQueryClient();
  const [selectedSiteContent, setSelectedSiteContent] = useState();
  // const { isLoading, isError, data, error } = useQuery(['contact-text', selectedSiteContent], () =>
  //   getSiteContent('contact-text'), // using the api slug here
  // );
  const { isPending, isError, data, error } = useQuery({
    queryKey: ['contact-text', { selectedSiteContent }],
    queryFn: () => getSiteContent('contact-text'), // using the api slug here
  });
  if (isPending) {
    return <div align="center"><Spinner /></div>;
  }
  if (isError) {
    return <p>Error from the contact text in site contents: {error.message}</p>;
  }
  let contacttext = data;

  return (
    <>
      <ReactMarkdown>{contacttext.description}</ReactMarkdown>
    </>
  );
}
