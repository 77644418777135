// ui_frontend/templates/ui/src/routes/Confirmation.jsx
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import Title from '../components/Title';

import BaseImage1 from '../assets/BaseImage1.jpg';
import MainTopSite from '../assets/MainTopSite.jpg';

// I did not add a Title from helmet to this page as it is unnecessary for it
// to be searched for.
const Confirmation = () => {
  return (
    <>
      <Title
        title="Knots & Knowledge | Confirmation"
        description="Thank you for sending a message to Knots & Knowledge"
        keywords="woodworking, wood crafting, wooden, crochet, crafting, contact confirmation, site"
      />
      <div className="extra-space-top-only-image">

        <Card className="card-hidden-behind">
          <Image
            src={MainTopSite}
            alt="Woodworking and craft images"
            fluid
          />
        </Card>
        <br />

        <div className="container-center content-wrapper">
        <br />
        <Row>
          <h1 align="center">Your message has been sent.</h1>
        </Row>
          <p>
            Thank you! Your message has been successfully sent. Someone will
            get back to you soon. There are more posts on the site about crafting,
            woodworking, crochet, gift giving, decor, safety, and more. Would you
            like to return to browsing our posts or head back to the homepage?
          </p>
          <br />
          <Row align="center">
            <Col>
              <Link to="/home" className="link-router">
                <button className="btn-static-one">To Get to the Home Page</button>
              </Link>
            </Col>
            <Col>
              <Link to="/posts" className="link-router">
                <button className="btn-static-one">
                  Go to the Posts Page
                </button>
              </Link>
            </Col>
          </Row>
        </div>

        <div>
        <br />
          <Card className="card-hidden-behind">
            <Image
              src={BaseImage1}
              alt="Interstial Stained Glass Image"
              fluid
            />
          </Card>
        </div>
      </div>
    </>
  );
};

export default Confirmation;
