// ui_frontend/templates/ui/src/datacalls/getItems.js
import axios from 'axios';

// These Axios calls are used to call for items in different parts of the site
// like at the beginning of the navigation bar and in items

export async function getPosts() {
    const apiUrl = process.env.BACK_TEST_URL
    const { data } = await axios.get(`${apiUrl}/get-posts/`);
    let posts = data;
    // console.log("posts in getPosts ", posts);
    return posts;
}

export async function getPost(selectedPost) {
    const apiUrl = process.env.BACK_TEST_URL;
    const { data } = await axios.get(`${apiUrl}/get-posts/${selectedPost}`);
    let post = data;
    // console.log("This is a getPost single post ", post);
    if (!selectedPost) {
      return null;
    } else {
    return post;
  }
}

export async function getRecommends(selectedRecommends) {
    const apiUrl = process.env.BACK_TEST_URL;
    const { data } = await axios.get(`${apiUrl}/get-recommends/${selectedRecommends}`)
    let recommends = data;
    // console.log("Get the recommends in getRecommends: ", recommends);
    if (!selectedRecommends) {
      return null;
    } else {
      return recommends;
    }
}

export async function getAllRecommends() {
    const apiUrl = process.env.BACK_TEST_URL;
    const { data } = await axios.get(`${apiUrl}/get-recommends/`)
    let allrecommends = data;
    // console.log("allrecommends in getAllRecommends ", allrecommends);
    return allrecommends;
}
