// ui_frontend/templates/ui/src/datacalls/getFortunes.js
import axios from 'axios';

export async function getFortunes() {
    const apiUrl = process.env.BACK_TEST_URL
    const { data } = await axios.get(`${apiUrl}/fortuneapi/`);
    let fortunes = data;
    // console.log("fortunes in getFortunes ", fortunes);
    return fortunes;
}
