// ui_frontend/templates/ui/src/siteContents/WelcomeText.jsx
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { getSiteContents, getSiteContent } from '../datacalls/getSiteContent';
import Spinner from '../components/Spinner';
// The following is the relevant information from the model for site contents
// in Django's backend.
// WELCOME_TEXT = 'welcome-text'

export default function WelcomeText(props) {
  const queryClient = useQueryClient();
  const [selectedSiteContent, setSelectedSiteContent] = useState();
  // const { isLoading, isError, data, error } = useQuery(['welcome-text', selectedSiteContent], () =>
  //   getSiteContent('welcome-text'), // using the api slug here
  // );
  const { isPending, isError, data, error } = useQuery({
    queryKey: ['welcome-text', { selectedSiteContent }],
    queryFn: () => getSiteContent('welcome-text'), // using the api slug here
  });
  if (isPending) {
    return <div align="center"><Spinner /></div>;
  }
  if (isError) {
    return <p>Error from the welcome text in site contents: {error.message}</p>;
  }
  let welcometext = data;
  // the following item that is commented out can be helpful with debugging
  // console.log("The welcometext in the welcome text file: ", welcometext)

  return (
    <>
      <ReactMarkdown>{welcometext.description}</ReactMarkdown>
    </>
  );
}
