// ui_frontend/templates/ui/src/posts/OneRec.jsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import dateFormat, { masks } from 'dateformat';
import ReactMarkdown from 'react-markdown';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Title from '../components/Title';
import { FaExternalLinkAlt } from 'react-icons/fa';
import DefaultRecommend from '../assets/DefaultRecommend.jpg';

const OneRec = ({recommend}) => {
  // The following is necessary to for prices if they are large
  // I'm leaving it in because it's the kind of thing that might be needed
  // generally in pricing
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function formatPrice(x) {
    const roundedNumber = Math.round(x * 100) / 100; // Round to two decimal places
    return roundedNumber.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  if (recommend.recommend_link === 'http://fake.us') {
    return (
      <>
      <div>
      <Container className="container-center-flex">
        <Card className={`card-${recommend.card_background_type}`}>
        <Row align="center">
          <Col align="center">
            <Image
              src={recommend.recommend_image}
              alt={`Photo for: ${recommend.recommend_name}`}
              align="center"
              fluid
            />
          </Col>
          </Row>
        </Card>
      </Container>
      <br />
      <h2 align="center">
        {recommend.recommend_name}
      </h2>
      <br />
        <ReactMarkdown>
          {recommend.recommend_description}
        </ReactMarkdown>
      <br />
      </div>
    </>
    );
  } else if ((recommend.recommend_link !== 'http://fake.us') && (recommend.price == 0.00)) {
    return (
      <>
      <div>
        <Container className="container-center-flex">
          <a
            href={`${recommend.recommend_link}`}
            target="_blank"
            rel="noopener noreferrer"
          >
          <Card className={`card-${recommend.card_background_type}`}>
            <Row align="center">
              <Col align="center">
                <Image
                  src={recommend.recommend_image}
                  alt={`Photo for: ${recommend.recommend_name}`}
                  align="center"
                  fluid
                />
              </Col>
            </Row>
          </Card>
          </a>
        </Container>
        <br />
        <h2 align="center">
          {recommend.recommend_name}
        </h2>
        <br />
          <ReactMarkdown>
            {recommend.recommend_description}
          </ReactMarkdown>
        <Row>
          <a
            href={`${recommend.recommend_link}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className={`btn-${recommend.card_background_type}`}>
               {recommend.recommend_company} <FaExternalLinkAlt />
            </button>
          </a>
        </Row>
        <br />
        </div>
      </>
    );
  } else if ((recommend.recommend_link !== 'http://fake.us') && (recommend.price != 0.0)) {
    return (
      <>
      <div>
        <Container className="container-center-flex">
          <a
            href={`${recommend.recommend_link}`}
            target="_blank"
            rel="noopener noreferrer"
          >
          <Card className={`card-${recommend.card_background_type}`}>
            <Row align="center">
              <Col align="center">
                <Image
                  src={recommend.recommend_image}
                  alt={`Photo for: ${recommend.recommend_name}`}
                  align="center"
                  fluid
                />
              </Col>
            </Row>
          </Card>
          </a>
        </Container>
        <br />
        <h2 align="center">
          {recommend.recommend_name}
        </h2>
        <br />
          <ReactMarkdown>
            {recommend.recommend_description}
          </ReactMarkdown>
        <Row>
          <a
            href={`${recommend.recommend_link}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className={`btn-${recommend.card_background_type}`}>
              {recommend.recommend_name} for ${formatPrice(Number(recommend.price))} from {recommend.recommend_company} <FaExternalLinkAlt />
            </button>
          </a>
        </Row>
        <br />
        </div>
      </>
    );
  }
};

export default OneRec;


// if (recommend.recommend_link !== 'http://fake.us') {
//   return (
//     <>
//       <h2 align="center">
//         {recommend.recommend_name}
//       </h2>
//       <a
//         href={`${recommend.recommend_link}`}
//         target="_blank"
//         rel="noopener noreferrer"
//       >
//         <div className="text-center">
//         <Card align="center" className={`card-${recommend.card_background_type}`}>
//           <Row align="center">
//             <Col align="center">
//               <Image
//                 height={250}
//                 src={recommend.recommend_image}
//                 alt={`Photo for: ${recommend.recommend_name}`}
//                 align="center"
//                 fluid
//               />
//             </Col>
//           </Row>
//         </Card>
//         </div>
//       </a>
//       <br />
//       <br />
//       <Row>
//         <ReactMarkdown>
//           {recommend.recommend_description}
//         </ReactMarkdown>
//       </Row>
//       <Row>
//         <a
//           href={`${recommend.recommend_link}`}
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           <button className={`btn-${recommend.card_background_type}`}>
//             Available for: ${numberWithCommas(Number(recommend.price))} from {recommend.recommend_company} <FaExternalLinkAlt />
//           </button>
//         </a>
//       </Row>
//       <br />
//     </>
//   );
// } else if (recommend.recommend_link === 'http://fake.us') {
//   return (
//     <>
//     <h2 align="center">
//       {recommend.recommend_name}
//     </h2>
//     <div className="text-center">
//       <Card align="center" className={`card-${recommend.card_background_type}`}>
//         <div className="text-center">
//           <Image
//             height={400}
//             src={recommend.recommend_image}
//             alt={`Photo for: ${recommend.recommend_name}`}
//             fluid
//           />
//         </div>
//       </Card>
//     </div>
//     <br />
//     <br />
//     <Row>
//       <ReactMarkdown>
//         {recommend.recommend_description}
//       </ReactMarkdown>
//     </Row>
//     <br />
//   </>
//   );
// }
// };
