// ui_frontend/templates/ui/src/routes/Home.jsx
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Figure from 'react-bootstrap/Figure';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import {
  FaExternalLinkAlt
} from 'react-icons/fa';

import AnnouncementText from '../sitecontents/AnnouncementText';
import WelcomeText from '../sitecontents/WelcomeText';
import Title from '../components/Title';
import Classes from '../components/Classes';

import StainedGlass from '../assets/StainedGlass.jpg';
import MainTopSite from '../assets/MainTopSite.jpg';

// <div className="content-wrapper">
//   <h2 align="center">Woodworking, Crafting, Recommendations, and More</h2>
//   <AnnouncementText />
//   <br />
// </div>

// The homepage contains markdown text, images, and a form that is mainly a
// demonstration of redux.
const Home = () => {

  return (
    <>
      <Title
        title="Knots & Knowledge | Home"
        description="This site is for those who are interested in taking up new artistic creative pursuits like woodworking, crochet, drawing, and more. Learning something new and creative brings such a sense of accomplishment and is great for mental health. This site also contains lists of creative items and educational toys as well."
        keywords="woodworking, crafting, pinterest, django, react, crochet, home decor, toys, lessons, craft lessons, woodworking lessons"
      />
      <div className="extra-space-top-only-image">
      <Card className="card-hidden-behind">
        <Image
          src={MainTopSite}
          alt="Crafty Images for the top of the site"
          fluid
        />
      </Card>
      <br />
        <Row>
          <h1 align="center">Knots & Knowledge</h1>
        </Row>
        <div className="content-wrapper">
          <WelcomeText />
        </div>
        <br />
        <div>
        <Classes />
        <br />
        <Card className="card-hidden-behind">
          <Image
            src={StainedGlass}
            alt="Interstial Stained Glass Image"
            fluid
          />
        </Card>
        </div>
      </div>
    </>
  );
};

export default Home;
