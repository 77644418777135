import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useMiniSearch } from 'react-minisearch';

import Container from 'react-bootstrap/Container';

import Spinner from './Spinner';
import useFetchAndProcessData from '../datacalls/useFetchAndProcessData';


const miniSearchOptions = ({
  fields: ['title', 'intro'],
  storeFields: ['title', 'slug', 'intro'],
  searchOptions: {
    boost: { title: 2 },
    fuzzy: 0.2
  }
});

const Search = ({ documents }) => {
  const { search, searchResults } = useMiniSearch(documents, miniSearchOptions);
  const [query, setQuery] = useState('');
  const handleSearchChange = (event) => {
    const newQuery = event.target.value;
    setQuery(newQuery);
    search(newQuery);
  };
  return (
    <div>
      <input
        type='text'
        onChange={handleSearchChange}
        value={query}
        placeholder='Search . . .'
      />
      <br />
      <br />
      {query && ( // Only render if there's a query
        <>
          <h3>Search Results:</h3>
          <ul>
            {searchResults && searchResults.length > 0 ? (
              searchResults.map((result, i) => (
                <li key={i}>
                  <Link to={`/posts/${result.slug}`} className="link-router">
                    {result.title}
                  </Link>
                </li>
              ))
            ) : (
              <p>No Results Found</p>
            )}
          </ul>
        </>
      )}
    </div>
  );
};

const SearchContainer = () => {
  const { documents, loading, error } = useFetchAndProcessData();

  if (loading) return <div align="center"><Spinner /></div>;
  if (error) return <div align="center">An error has occurred: {error.message}</div>;

  return <Search documents={documents} />;
};

export default SearchContainer;


// const handleSearchChange = (event) => {
//   const query = event.target.value;
//   // console.log('Search Query:', query);
//   search(query);
// };
// useEffect(() => {
//   console.log('Search Results:', searchResults);
// }, [searchResults]);
// <h3>Search Results:</h3>
// <ul>
//   {searchResults && searchResults.length > 0 ? (
//     searchResults.map((result, i) => (
//       <li key={i}>
//         <Link to={`/posts/${result.slug}`} className="link-router">
//           {result.title}
//         </Link>
//       </li>
//     ))
//   ) : (
//     <p>No Results Found</p>
//   )}
// </ul>
// const getProcessedData = (postsData, recommendsData) => {
//   const searchPosts = postsData || [];
//   const searchRecommends = recommendsData || [];
//
//   const searchArrayPosts = searchPosts.map((post) => {
//     let modifiedPost = { ...post };
//
//     if (modifiedPost.intro && modifiedPost.outro) {
//       modifiedPost.intro += " " + modifiedPost.outro;
//       delete modifiedPost.outro;
//     }
//
//     searchRecommends.forEach((recommend) => {
//       if (recommend.rec_slug === post.slug) {
//         if (recommend.rec_desc) {
//           if (modifiedPost.intro) {
//             modifiedPost.intro += " " + recommend.rec_desc;
//           } else {
//             modifiedPost.intro = recommend.rec_desc;
//           }
//         }
//       }
//     });
//
//     if (modifiedPost.intro) {
//       modifiedPost.intro = modifiedPost.intro.replace(/\r\n\r\n/g, " ");
//     }
//
//     return {
//       id: modifiedPost.id,
//       slug: modifiedPost.slug,
//       title: modifiedPost.title,
//       intro: modifiedPost.intro,
//     };
//   });
//   return searchArrayPosts;
// };



// const Search = () => {
//   const [{
//           data: postsData,
//           isLoading: postsLoading,
//           error: postsError
//         },
//         {
//           data: recommendsData,
//           isLoading: recommendsLoading,
//           error: recommendsError
//         }] = useQueries({
//       queries: [
//         {
//           queryKey: ['posts'],
//           queryFn: getPosts,
//           select: (posts) =>
//             posts.map((post) => ({
//               id: post.pkid,
//               title: post.title,
//               slug: post.slug_post,
//               intro: post.post_intro,
//               outro: post.post_outro
//           })),
//         },
//         {
//           queryKey: ['allrecommends'],
//           queryFn: getAllRecommends,
//           select: (allrecommends) =>
//             allrecommends.map((recommend) => ({
//               rec_name: recommend.recommend_name,
//               rec_slug: recommend.slug_post_rec,
//               rec_desc: recommend.recommend_description
//           })),
//         },
//       ],
//   });
//   const [documents, setDocuments] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const { search, searchResults } = useMiniSearch(documents, miniSearchOptions);
//
//   useEffect(() => {
//     if (postsLoading || recommendsLoading) {
//       setLoading(true);
//     } else if (postsError || recommendsError) {
//       setError(postsError || recommendsError);
//       setLoading(false);
//     } else if (postsData && recommendsData) {
//       const processedData = getProcessedData(postsData, recommendsData);
//       setDocuments(processedData);
//       console.log("the processedData below");
//       console.log(processedData);
//       setLoading(false);
//     }
//   }, [postsLoading, recommendsLoading, postsError, recommendsError, postsData, recommendsData]);
//
//   const handleSearchChange = (event) => {
//     const query = event.target.value;
//     console.log('Search Query:', query);
//     search(query);
//   };
//   // const handleSearchChange = (event) => {
//   //   search(event.target.value);
//   // };
//   useEffect(() => {
//     console.log('Documents:', documents);
//   }, [documents]);
//
//   console.log('Check if array is array ', Array.isArray(documents));
//
//   useEffect(() => {
//     console.log('Search Results:', searchResults);
//   }, [searchResults]);
//
//   console.log("Shows what miniSearchOptions from within search");
//   console.log(miniSearchOptions);
//
//   if (loading) return <div align="center"><Spinner /></div>;
//   if (error) return <div align="center">An error has occurred: {error.message}</div>;
//
//   console.log("documents", documents, "searchResults", searchResults);
//   console.log(documents);
//
//   return (
//     <div>
//       <input
//         type='text'
//         onChange={handleSearchChange}
//         placeholder='Search . . .'
//       />
//       <ol>
//         <h3>Results:</h3>
//           {searchResults && searchResults.length > 0 ? (
//             searchResults.map((result, i) => (
//               <li key={i}>{result.title}</li>
//             ))
//             ) : (
//             <li>No results found</li>
//           )}
//       </ol>
//     </div>
//   );
// };
//
// export default Search;
//

// // Trying to put these together as a single article with renames maybe then search
// // The whole text together first so it can search it
// // May need it to only render when available - could be complicated
// // Can add maybe top 100 characters

// See MiniSearch for documentation on options
// const miniSearchOptions = ({
//   fields: ['id','slug', 'title', 'intro'],
//   storeFields: ['id', 'slug', 'title', 'intro'],
// });
// const documents = [
//   { id: 1, name: 'Agata', intro: 'An intro thing' },
//   { id: 2, name: 'Finn', intro: 'Another second intro thing' },
//   { id: 3, name: 'James', intro: 'More nonsense' },
//   { id: 4, name: 'Harper', intro: 'Here is another intro' },
// ];
//
// const miniSearchOptions = { fields: ['name', 'intro'] };
//
// const Search = () => {
//   const { search, searchResults } = useMiniSearch(documents, miniSearchOptions);
//
//   const handleSearchChange = (event) => {
//     search(event.target.value);
//   };
//
//   return (
//     <div>
//       <input type='text' onChange={handleSearchChange} placeholder='Search…' />
//
//       <ol>
//         <h3>Results:</h3>
//         {
//           searchResults && searchResults.map((result, i) => {
//             return <li key={i}>{ result.name }</li>
//           })
//         }
//       </ol>
//     </div>
//   );
// };
//
// export default Search;
// const [searchQuery, setSearchQuery] = useState('');
// const handleSearchChange = (event) => {
//   const query = event.target.value;
//   console.log('Search Query:', query);
//   search(query);
// };
// <input
//   type="text"
//   onChange={handleSearchChange}
//   value={searchQuery}
//   placeholder="Search Here"
// />
// {searchQuery && (
//   <ol>
//     <h3>Results:</h3>
//     {
//       searchResults && searchResults.map((result, i) => (
//         <li key={i}>{result.title}</li>
//       ))
//     }
//   </ol>
// )}
// useEffect(() => {
//   const fetchData = async () => {
//     try {
//       const documents = await getProcessedData();
//       if (documents.loading) {
//         setLoading(true);
//       } else if (documents.error) {
//         setError(documents.error);
//       } else {
//         setDocuments(documents);
//         setLoading(false);
//       }
//     } catch (err) {
//       setError(err);
//       setLoading(false);
//     }
//   };
//   fetchData();
// }, []);
// useEffect(() => {
//   const fetchData = async () => {
//     try {
//       const processedData = await getProcessedData();
//       if (processedData.loading) {
//         setLoading(true);
//       } else if (processedData.error) {
//         setError(processedData.error);
//       } else {
//         setDocuments(processedData);
//       }
//     } catch (err) {
//       setError(err);
//     } finally {
//       setLoading(false);
//     }
//   };
//
//   fetchData();
// }, []);
//
// const handleSearchChange = (event) => {
//   search(event.target.value)
// };
// const getProcessedData = async () => {
//   const [postsSearchQuery, recommendsSearchQuery] = await useQueries({
//       queries: [
//         {
//           queryKey: ['posts'],
//           queryFn: getPosts,
//           select: (posts) =>
//             posts.map((post) => ({
//               id: post.pkid,
//               title: post.title,
//               slug: post.slug_post,
//               intro: post.post_intro,
//               outro: post.post_outro
//           })),
//         },
//         {
//           queryKey: ['allrecommends'],
//           queryFn: getAllRecommends,
//           select: (allrecommends) =>
//             allrecommends.map((recommend) => ({
//               rec_name: recommend.recommend_name,
//               rec_slug: recommend.slug_post_rec,
//               rec_desc: recommend.recommend_description
//           })),
//         },
//       ],
//     });
//
//     if (postsSearchQuery.isPending) return <div align="center"><Spinner /></div>;
//     if (recommendsSearchQuery.isPending) return <div align="center"><Spinner /></div>;
//
//     if (postsSearchQuery.error)
//       return 'An error has occurred in posts: ' + postsSearchQuery.error.message;
//
//     if (recommendsSearchQuery.error)
//       return 'An error has occurred in posts: ' + recommendsSearchQuery.error.message;
//
//     // let searchPosts = postsSearchQuery.data || [];
//     const searchPosts = postsSearchQuery.data;
//     console.log("The following is the results of seachPosts from let searchPosts = postsSeachQuery.data");
//     console.log(searchPosts);
//
//     // let searchRecommends = recommendsSearchQuery.data || [];
//     const searchRecommends = recommendsSearchQuery.data;
//     console.log("The following is the results of searchRecommends from let searchRecommends = recommendsSearchQuery.data");
//     console.log(searchRecommends);
//     console.log("Below is the inside array called searchArrayPosts");
//     // if (postsSearchQuery.isLoading || recommendsSearchQuery.isLoading) {
//     //     return { loading: true };
//     //   }
//     //
//     // if (postsSearchQuery.error || recommendsSearchQuery.error) {
//     //   return { error: postsSearchQuery.error || recommendsSearchQuery.error };
//     // }
//     // const searchPosts = postsSearchQuery.data || [];
//     // const searchRecommends = recommendsSearchQuery.data || [];
//     const searchArrayPosts = searchPosts.map((post) => {
//       let modifiedPost = { ...post };
//
//       if (modifiedPost.intro && modifiedPost.outro) {
//         modifiedPost.intro += " " + modifiedPost.outro;
//         delete modifiedPost.outro;
//       }
//       searchRecommends.forEach((recommend) => {
//         if (recommend.rec_slug === post.slug) {
//           if (recommend.rec_desc) {
//             if (modifiedPost.intro) {
//               modifiedPost.intro += " " + recommend.rec_desc;
//             } else {
//               modifiedPost.intro = recommend.rec_desc;
//             }
//           }
//         }
//       });
//     // const searchArrayPosts = searchPosts.map(post => {
//     //   // Create a copy of the post object to avoid mutating the original
//     //   let modifiedPost = { ...post };
//     //
//     //   // Combine the strings if necessary
//     //   if (modifiedPost.intro && modifiedPost.outro) {
//     //     modifiedPost.intro += " " + modifiedPost.outro;
//     //     delete modifiedPost.outro;
//     //   }
//       // Remove "\r\n\r\n" from strings
//       if (modifiedPost.intro) {
//         modifiedPost.intro = modifiedPost.intro.replace(/\r\n\r\n/g, " ");
//       }
//       return {
//         id: modifiedPost.id,
//         title: modifiedPost.title,
//         slug: modifiedPost.slug,
//         intro: modifiedPost.intro,
//       };
//    });
//    return searchArrayPosts;
// };
// const gatherById = (documents) => {
//   // Check if documents is an array
//   if (!Array.isArray(documents)) {
//     console.error("Error: documents is not an array");
//     return {};
//   }
//
//   // Check if documents is empty
//   if (documents.length === 0) {
//     console.warn("Warning: documents array is empty");
//     return {};
//   }
//
//   // Ensure each document is an object
//   const isValidDocuments = documents.every(doc => typeof doc === 'object');
//   if (!isValidDocuments) {
//     console.error("Error: documents array contains non-object elements");
//     return {};
//   }
//
//   // Assuming extractField function is defined correctly
//   return documents.reduce((byId, doc) => {
//     const id = extractField(doc, idField); // Assuming idField is defined somewhere
//     byId[id] = doc;
//     return byId;
//   }, {});
// };
// if (checkDoc == true) {
// return (
//   <div>
//     <input type='text' onChange={handleSearchChange} placeholder='Search . . .' />
//
//     <ol>
//       <h3>Results:</h3>
//       {
//         searchResults && searchResults.map((result, i) => {
//           return <li key={i}>{ result.title }</li>
//         })
//       }
//     </ol>
//   </div>
// );
// } else {
//   return (
//     <h1>Documents doesn't exist</h1>
//   );
// }
// const miniSearchOptions = ({
//   fields: ['intro', 'title'],
//   storeFields: ['title', 'slug'],
// });
//
// export default function Search(props) {
//
//   const { search, searchResults } = useMiniSearch(documents, miniSearchOptions);
//
//   const [postsSearchQuery, recommendsSearchQuery] = useQueries({
//     queries: [
//       {
//         queryKey: ['posts'],
//         queryFn: getPosts,
//         select: (posts) =>
//           posts.map((post) => ({
//             id: post.pkid,
//             title: post.title,
//             slug: post.slug_post,
//             intro: post.post_intro,
//             outro: post.post_outro
//         })),
//       },
//       {
//         queryKey: ['allrecommends'],
//         queryFn: getAllRecommends,
//         select: (allrecommends) =>
//           allrecommends.map((recommend) => ({
//             rec_name: recommend.recommend_name,
//             rec_slug: recommend.slug_post_rec,
//             rec_desc: recommend.recommend_description
//         })),
//       },
//     ],
//   });
//   if (postsSearchQuery.isPending) return <div align="center"><Spinner /></div>;
//   if (recommendsSearchQuery.isPending) return <div align="center"><Spinner /></div>;
//
//   if (postsSearchQuery.error)
//     return 'An error has occurred in posts: ' + postsSearchQuery.error.message;
//
//   if (recommendsSearchQuery.error)
//     return 'An error has occurred in posts: ' + recommendsSearchQuery.error.message;
//
//   let searchPosts = postsSearchQuery.data;
//   console.log("The following is the results of seachPosts from let searchPosts = postsSeachQuery.data");
//   console.log(searchPosts);
//
//   let searchRecommends = recommendsSearchQuery.data;
//   console.log("The following is the results of searchRecommends from let searchRecommends = recommendsSearchQuery.data");
//   console.log(searchRecommends);
//
//   console.log("Below is the inside array called searchArrayPosts")
//   const searchArrayPosts = searchPosts.map(post => {
//     // Create a copy of the post object to avoid mutating the original
//     let modifiedPost = { ...post };
//
//     // Combine the strings if necessary
//     if (modifiedPost.intro && modifiedPost.outro) {
//       modifiedPost.intro += " " + modifiedPost.outro;
//       delete modifiedPost.outro;
//     }
//     // Append additional data from recommends
//     searchRecommends.forEach(recommend => {
//       if (recommend.rec_slug === post.slug) {
//         if (recommend.rec_desc) {
//           if (modifiedPost.intro) {
//             modifiedPost.intro += " " + recommend.rec_desc;
//           } else {
//             modifiedPost.intro = recommend.rec_desc;
//           }
//         }
//         // Add other properties from recommend if needed
//       }
//     });
//     // Remove "\r\n\r\n" from strings
//     if (modifiedPost.intro) {
//       modifiedPost.intro = modifiedPost.intro.replace(/\r\n\r\n/g, " ");
//     }
//     return modifiedPost;
//   });
//   console.log(searchArrayPosts);
//   let documents = searchArrayPosts;
//   console.log("The following is documents.");
//   console.log(documents);
//   // let miniSearch = new MiniSearch({
//   //   fields: ['title', 'intro'], // fields to index for full-text search
//   //   storeFields: ['title', 'slug'], // fields to return with search results
//   // });
//   const handleSearchChange = (event) => {
//     search(event.target.value)
//   };
//
//   return (
//     <>
//       <input type="text" onChange={handleSearchChange} placeholder="Search . . ." />
//       <br />
//       <ol>
//         <h3>Results:</h3>
//         {
//           searchResults && searchResults.map((result, i) => {
//             return (
//               <li>
//                 <a key={i} href={`https://knotsandknowledge/posts/${result.slug}`} >
//                   {result.title}
//                 </a>
//               </li>
//             );
//         })
//       }
//       </ol>
//     </>
//   );
// }



// <Form>
//   <Row>
//     <Col sm={6}>
//     <Form.Group className="mb-3" controlId="formBasicName">
//       <Form.Control
//         type="search"
//         size="lg"
//         placeholder="Search . . ."
//         onChange={handleSearchChange}
//       />
//     </Form.Group>
//     </Col>
//     <Col sm={6}>
//     <button
//       name="The submit a search button"
//       className="nova-button"
//       type="submit"
//     >
//       Search
//     </button>
//     </Col>
//   </Row>
// </Form>
// <ul>
// {searchArrayPosts.map(function(data) {
//   return (
//     <li>{data}</li>
//   )
// })}
// </ul>
// <br />
// <Row>
//   {searchArrayPosts}
// </Row>
// const searchArrayPosts = searchPosts.forEach(subArray => {
//   subArray.forEach(element => {
//     console.log(element);
//   });
// });
// console.log(searchArrayPosts);
// {searchPosts.map(function(data) {
//   return (
//     <div>
//       <a href={`${currentUrl}/posts/${slug-post}`}>
//         {post.title}
//       </a>
//     </div>
//   )
// })}
// for (const [index, element] of searchPosts.entries()) {
//   console.log(index, element);
// }
// console.log("Next line is Array.isArray(searchPosts)");
// console.log(Array.isArray(searchPosts));
//
// const iterator1 = searchPosts[Symbol.iterator]();
//
// for (const value of iterator1) {
//   console.log(value);
// }
// const searchData = ({post}) => {
//   return [{post.title}, {post.slug_post}]
// }
// recommends.map((recommend) => {
//   if (post.slug_post === recommend.slug_post_rec) {
//     return (
//       <Row key={recommend.pkid} className="extra-space">
//         <OneRec recommend={recommend} />
//       </Row>
//     );
//   }
// })
// useEffect(() => {
//   // Merge lists provisionally based on id, selecting specific fields
//   const searchData = searchPosts.map(onepost => {
//     const findRecommends = searchRecommends.find(onerecommend => onerecommend.recommend.slug_post_rec === onepost.post.slug_post);
//     if ()
//     return {
//       slug: onepost.post.slug_post,
//       title: post.title,
//       intro: post.post_intro,
//       outro: post.post_intro,
//     };
//   }).concat(list2.filter(item2 => !list1.some(item1 => item1.id === item2.id)));
//   setSearchData(searchData);
// }, []); // Empty dependency array to run the effect only once on mount
// const { status, data, error } = useQuery({
//   queryKey: ['posts'],
//   queryFn: async () => getPosts,
//   select: (posts) => posts.map((post) => [post.title, post.slug_post, post.post_intro, post.post_outro]),
// });
//
// let searchposts = data;
// <Row className="card-container">
//   {status === 'pending' ? (
//     <Spinner />
//   ) : status === 'success' ? (
//     searches.map((search) => {
//       return (
//         <Row>
//           <a href={`${selfUrl}/posts/${search.slug_post}`}>
//             <h2>{search.title}</h2>
//           </a>
//         </Row>
//       );
//     })
//   ) : status === 'error' ? (
//     <Row>
//       <p>No matches found</p>
//     </Row>
//   ) : null}
//   </Row>
// const { status, data, error } = useQuery({
//   queryKey: ['posts'],
//   queryFn: async () => getPosts,
// });
