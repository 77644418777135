// ui_frontend/templates/ui/src/datacalls/urlLoaders.js
import { redirect } from 'react-router-dom';
import {
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { getPosts, getPost, getRecommends, getAllRecommends } from './getPosts';

// The following are used on a single item page and a single article page to
// show either that it is an item page or a 404 page. It just makes it a this or
// that choice to show either an error or the page.

export function isPostUrl(checkId) {
  const { status, data, error } = useQuery({
    queryKey: ['posts'],
    queryFn: () => getPosts,
    retry: 6, // Number of retries
    retryDelay: 1000, // Delay between retries in milliseconds
  });
  if (status === 'pending') {
    return <p>isPostUrl is checking post url names</p>;
  }
  if (status === 'error') {
    return <p>Error from the isPostUrl: {error.message}</p>;
  }
  let allposts = data;
  const postsIDList = allposts.map((posts) => (posts.slug_post));
  const checkPosts = postsIDList.includes(checkId);
  return checkPosts;
}

export function isRecommend(checkId) {
  const { status, data, error } = useQuery({
    queryKey: ['allrecommends'],
    queryFn: () => getAllRecommends,
  });
  if (status === 'pending') {
    return <p>isRecommend is checking for recommends for this post</p>;
  }
  if (status === 'error') {
    return <p>Error from the isRecommend: {error.message}</p>;
  }
  let totalrecommends = data;
  const recommendsIDList = totalrecommends.map((recommends) => (recommends.slug_post_rec));
  const checkRecommends = recommendsIDList.includes(checkId);
  return checkRecommends;
}



// const recommendIDList = allrecommends.map((recommends) => (recommends.slug_post_rec));
// const checkRecommends = recommendIDList.includes(checkId);
