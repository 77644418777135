// ui_frontend/templates/ui/src/components/Title.jsx
import React from 'react';
import { Helmet } from 'react-helmet';

// I've mainly used 10 keyowords. React Helmet simplifies making meta tags and
// and page titles. On the pages this component is imported into and used in,
// you can add a title, description, and/or keywords. You can add one, two, or
// all three. You are not limited to adding all three or none of them when using
// it on another page. It will use the default when imported and not specified.
const Title = ({ title, description, keywords }) => {
	return (
		<Helmet>
			<title>{title}</title>
			<meta name="description" content={description} />
			<meta name="keywords" content={keywords} />
		</Helmet>
	);
};

Title.defaultProps = {
	title: "Knots & Knowledge",
	description: "Learn about woodworking, crafting, and get new ideas.",
	keywords: "woodworking, pinterest, crochet, crafting, selling online, selling etsy, wood crafts"
};

export default Title;
