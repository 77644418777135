// ui_frontend/templates/ui/src/buttons/ToggleSwitchDayNight.js
import React from 'react';

// This is the actual switch toggle
const ToggleSwitchDayNight = ({ isDayNight, onChange }) => (
  <>
    <label className="switch">
      <span className="sun">
        <svg xmlns="http://www.w3.org/2000/svg" height="0.7em" viewBox="0 0 24 24">
          <g fill="#ffd43b">

            <circle r="5" cy="12" cx="12"></circle>

            <path d="m21 13h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm-17 0h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm13.66-5.66a1 1 0 0 1 -.66-.29 1 1 0 0 1 0-1.41l.71-.71a1 1 0 1 1 1.41 1.41l-.71.71a1 1 0 0 1 -.75.29zm-12.02 12.02a1 1 0 0 1 -.71-.29 1 1 0 0 1 0-1.41l.71-.66a1 1 0 0 1 1.41 1.41l-.71.71a1 1 0 0 1 -.7.24zm6.36-14.36a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm0 17a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm-5.66-14.66a1 1 0 0 1 -.7-.29l-.71-.71a1 1 0 0 1 1.41-1.41l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.29zm12.02 12.02a1 1 0 0 1 -.7-.29l-.66-.71a1 1 0 0 1 1.36-1.36l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.24z"></path>

          </g>
        </svg>
      </span>

      <span className="moon">
        <svg xmlns="http://www.w3.org/2000/svg" height="0.7em" viewBox="0 0 384 512">
          <path d="m223.5 32c-123.5 0-223.5 100.3-223.5 224s100 224 223.5 224c60.6 0 115.5-24.2 155.8-63.4 5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6-96.9 0-175.5-78.8-175.5-176 0-65.8 36-123.1 89.3-153.3 6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"></path>
        </svg>
      </span>

      <input
        type="checkbox"
        id="uniqueID"
        className="input"
        checked={isDayNight}
        onChange={onChange}
        aria-label="Switch between day and night"
      />
      <span className="slider"></span>
    </label>
  </>
);

export default ToggleSwitchDayNight;


// const ToggleSwitchDayNight = ({ isDayNight, onChange }) => (
//   <>
//     <label className="switch">
//       <span className="sun">
//         <svg xmlns="http://www.w3.org/2000/svg" height="0.7em" viewBox="0 0 24 24">
//           <g fill="#ffd43b">
//
//             <circle r="5" cy="12" cx="12"></circle>
//
//             <path d="m21 13h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm-17 0h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm13.66-5.66a1 1 0 0 1 -.66-.29 1 1 0 0 1 0-1.41l.71-.71a1 1 0 1 1 1.41 1.41l-.71.71a1 1 0 0 1 -.75.29zm-12.02 12.02a1 1 0 0 1 -.71-.29 1 1 0 0 1 0-1.41l.71-.66a1 1 0 0 1 1.41 1.41l-.71.71a1 1 0 0 1 -.7.24zm6.36-14.36a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm0 17a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm-5.66-14.66a1 1 0 0 1 -.7-.29l-.71-.71a1 1 0 0 1 1.41-1.41l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.29zm12.02 12.02a1 1 0 0 1 -.7-.29l-.66-.71a1 1 0 0 1 1.36-1.36l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.24z"></path>
//
//           </g>
//         </svg>
//       </span>
//
//       <span className="moon">
//         <svg xmlns="http://www.w3.org/2000/svg" height="0.7em" viewBox="0 0 384 512">
//           <path d="m223.5 32c-123.5 0-223.5 100.3-223.5 224s100 224 223.5 224c60.6 0 115.5-24.2 155.8-63.4 5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6-96.9 0-175.5-78.8-175.5-176 0-65.8 36-123.1 89.3-153.3 6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"></path>
//         </svg>
//       </span>
//
//       <input
//         type="checkbox"
//         id="uniqueID"
//         className="input"
//         checked={isDayNight}
//         onChange={onChange}
//         aria-label="Switch between day and night"
//       />
//       <span className="slider"></span>
//     </label>
//   </>
// );

// <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
// // This is the actual switch toggle
// const ToggleSwitchDayNight = ({ isDayNight, onChange }) => (
//   <>
//     <label className="toggle">
//       <span className="toggle-track">
//         <span className="toggle-indicator">
//           <span className="checkMark">
//             <svg xmlns="http://www.w3.org/2000/svg" height="0.7em" viewBox="0 0 512 512" aria-hidden="true">
//               <path d="M361.5 1.2c5 2.1 8.6 6.6 9.6 11.9L391 121l107.9 19.8c5.3 1 9.8 4.6 11.9 9.6s1.5 10.7-1.6 15.2L446.9 256l62.3 90.3c3.1 4.5 3.7 10.2 1.6 15.2s-6.6 8.6-11.9 9.6L391 391 371.1 498.9c-1 5.3-4.6 9.8-9.6 11.9s-10.7 1.5-15.2-1.6L256 446.9l-90.3 62.3c-4.5 3.1-10.2 3.7-15.2 1.6s-8.6-6.6-9.6-11.9L121 391 13.1 371.1c-5.3-1-9.8-4.6-11.9-9.6s-1.5-10.7 1.6-15.2L65.1 256 2.8 165.7c-3.1-4.5-3.7-10.2-1.6-15.2s6.6-8.6 11.9-9.6L121 121 140.9 13.1c1-5.3 4.6-9.8 9.6-11.9s10.7-1.5 15.2 1.6L256 65.1 346.3 2.8c4.5-3.1 10.2-3.7 15.2-1.6zM160 256a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zm224 0a128 128 0 1 0 -256 0 128 128 0 1 0 256 0z"></path>
//             </svg>
//           </span>
//         </span>
//       </span>
//     </label>
//   </>
// );
//
// export default ToggleSwitchDayNight;
