// ui_frontend/templates/ui/src/routes/ErrorNote.jsx
import React from 'react';
import {
  Link,
  useNavigation,
  useRouteError
} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import { TbSwords } from 'react-icons/tb';
import { GiLoveMystery, GiOwl, GiHelp, GiWyvern } from "react-icons/gi";
import { IoConstruct } from "react-icons/io5";
import Title from '../components/Title';

import ColorfulGlass from '../assets/ColorfulGlass.jpg';


export default function ErrorNote() {
  const error = useRouteError();
  console.error(error);

  return (
    <>
    <Title
      title="Knots & Knowledge | Error"
      description="The error page for Knots & Knowledge"
      keywords="error page, woodworking site, crafting site"
    />
    <div className="extra-space-top-only">
      <div className="content-wrapper">
      <Container className="container-center-flex">
        <Card className="card-static-one">
          <Card.Title>
            <h1>Oh No! An Error has occured . . .</h1>
            <p>This is unexpected. This url does not exist.</p>
          </Card.Title>
          <Card.Body>
            <Container align="center">
              <Row>
                <Col>
                  <GiLoveMystery className="icon-two" />
                  <GiHelp className="icon-three" />
                  <GiWyvern className="icon-two" />
                </Col>
              </Row>
            </Container>
            <br />
            <hr />
            <br />
            <Row align="center">
              <Col>
                <Link to="/home" className="link-router">
                  <button className="btn-static-two">Go to the Home Page</button>
                </Link>
              </Col>
              <Col>
                <Link to="/posts" className="link-router">
                  <button className="btn-static-two">
                    Go to the Posts Page
                  </button>
                </Link>
              </Col>
            </Row>
            <br />
            <br />
            <Row align="center">
              <i>Error Message is: {error.statusText || error.message}</i>
            </Row>
          </Card.Body>
        </Card>
      </Container>
      </div>
      <br />
      <div>
        <Card className="card-hidden-behind">
          <Image
            src={ColorfulGlass}
            alt="Knots and Knowledge stained glass image"
            fluid
          />
        </Card>
      </div>
    </div>
    </>
  );
}
