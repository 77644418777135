// ui_frontend/templates/ui/src/Contact.jsx
import { FaPaperPlane } from "react-icons/fa";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";

import Title from "../components/Title";
import ContactForm from "../contact/ContactForm";
import ContactText from "../sitecontents/ContactText";

import ResourcesTopPic from "../assets/ResourcesTopPic.jpg";

// This is a wrapper for the contact form, and it also contains the API endpoint.
// Remember to change the endpoint if you change to backend url name
const Contact = () => {
  return (
    <>
      <Title
        title="Knots & Knowledge | Contact"
        description="Use this page to contact the author of the Knots & Knowledge site. Knots & Knowledge is a site that helps connect you with resources so you can learn new woodworking, artistic, and crafting skills."
        keywords="woodworking site, contact me, crafting site, info site, contact form"
      />
      <div className="extra-space-top-only">
        <div className="container-center content-wrapper">
          <h1 align="center">
            Knots & Knowledge Contact Form <FaPaperPlane />
          </h1>
          <hr />
          <ContactText />
          <ContactForm endpoint="contact-back/v1/" />
          <br />
        </div>

        <div>
          <br />
          <Card className="card-hidden-behind">
            <Image
              src={ResourcesTopPic}
              alt="Arts and Crafts pictures on top of a wood image"
              fluid
            />
          </Card>
        </div>
      </div>
    </>
  );
};

export default Contact;
