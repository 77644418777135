// ui_frontend/templates/ui/src/posts/PartTwoPost.jsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import dateFormat, { masks } from 'dateformat';
import ReactMarkdown from 'react-markdown';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


const PartTwoPost = ({post}) => {

  return (
    <>
      <div>
        <ReactMarkdown>
          {post.post_outro}
        </ReactMarkdown>
      </div>
    </>
  );
};

export default PartTwoPost;
