// ui_frontend/templates/ui/src/components/Footer.jsx
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
// The following are from the react-icons site which you can find here:
// https://react-icons.github.io/react-icons
// There are other ways to add icons, I have found this way to be both simple
// and manageable in size.
import {
  FaPaperPlane,
  FaPinterest,
  FaExternalLinkAlt,
  FaInstagram
} from 'react-icons/fa';
import { GiBalloonDog, GiWoodenDoor, GiDaemonPull } from 'react-icons/gi';


const Footer = () => {
  return (
    <Nav>
      <Container fluid className="custom-footer">
        <Row align="center" className="justify-content-md-center row-back">

          <Col md="auto">
            <a href="/resources" rel="noopener noreferrer" className="footer-link">
              <span><GiBalloonDog /> Resources</span>
            </a>
          </Col>

          <Col md="auto">
            <a href="/contact" rel="noopener noreferrer" className="footer-link">
              <span><FaPaperPlane /> Contact</span>
            </a>
          </Col>

          <Col md="auto">
            <a
              href="https://www.pinterest.com/knotsandknowledge/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
            >
              <span><FaPinterest /> Pinterest <FaExternalLinkAlt /></span>
            </a>
          </Col>

          <Col md="auto">
            <a
              href="https://www.instagram.com/knotsandknowledge/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
            >
              <span><FaInstagram /> Insta <FaExternalLinkAlt /></span>
            </a>
          </Col>

          <Col md="auto">
            <a href="/posts" rel="noopener noreferrer" className="footer-link">
              <span><GiWoodenDoor /> Posts</span>
            </a>
          </Col>

          <Col md="auto">
            <a href="/teller" rel="noopener noreferrer" className="footer-link">
              <span><GiDaemonPull /> Fortunes</span>
            </a>
          </Col>

        </Row>
        <Row align="center" className="row-back">
          <span><b>Knots & Knowledge {new Date().getFullYear()}</b></span>
        </Row>
      </Container>
    </Nav>
  );
};

export default Footer;
