// ui_frontend/templates/ui/src/index.js
import React from 'react';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  RouterProvider,
  Route,
  Switch,
  // useLocation,
} from 'react-router-dom';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// Importing all the SASS (scss) files at the top of the frontend
import './styles/main.scss';

// The day night provider, the frontend is wrapped in it
import {
  ThemeDayNightProvider,
  useDayNightTheme,
} from './buttons/useDayNightTheme';

import Root from './routes/Root';
import ErrorNote from './routes/ErrorNote';
import Home from './routes/Home';
import About from './routes/About';
import Contact from './routes/Contact';
import Confirmation from './routes/Confirmation';
import Fortune from './routes/Fortune';
import FortuneTeller from './routes/FortuneTeller';
import Posts from './routes/Posts';
import Post from './routes/Post';
import Resources from './routes/Resources';


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 1000 * 10,
    },
  },
});


// Quick note. since this frontend is inside of a Django site, it does make
// dealing with error urls a bit complicated. It can be buggy. It is also why
// it redirects to /home instead of to /
const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
      children: [
        { errorElement: <ErrorNote />,
          children: [
            { index: true,
              element: <Navigate to="/home" replace />,
            },
            {
              path: "home",
              element: <Home />,
            },
            {
              path: "about",
              element: <About />,
            },
            {
              path: "contact",
              element: <Contact />,
            },
            {
              path: "confirmation",
              element: <Confirmation />,
            },
            {
              path: "teller",
              element: <FortuneTeller />,
            },
            {
              path: "fortune",
              element: <Fortune />,
            },
            {
              path: "resources",
              element: <Resources />,
            },
            {
              path: "posts",
              element: <Posts />,
            },
            {
              children: [
                {
                  path: "posts/:postId",
                  element: <Post />,
                },
                {
                  path: "posts/*",
                },
              ],
            },
            {
              path: "*",
              element: <ErrorNote />,
            }
          ],
        },
      ],
    },
]);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <QueryClientProvider client={queryClient}>
    <ThemeDayNightProvider>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </ThemeDayNightProvider>
    <ReactQueryDevtools initialIsOpen />
  </QueryClientProvider>
);
