// ui_frontend/templates/ui/src/routes/About.jsx
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Figure from "react-bootstrap/Figure";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";

import Title from "../components/Title";
import AboutText from "../sitecontents/AboutText";

import NewThinTop from "../assets/NewThinTop.jpg";
import BaseImageWithName from "../assets/BaseImageWithName.jpg";

const About = () => {
  return (
    <>
      <Title
        title="Knots & Knowledge | About"
        description="The about page Knots & Knowledge"
        keywords="About, woodworking, wood crafts, blog, wood crafting blog, crafting blog, crochet"
      />
      <div className="extra-space-top-only-image">
        <div>
          <Card className="card-hidden-behind">
            <Image src={NewThinTop} alt="Arts and Crafts images" fluid />
          </Card>
        </div>
        <div className="content-wrapper">
          <hr />
          <Row>
            <h1 align="center">About Knots & Knowledge</h1>
            <AboutText />
          </Row>
        </div>
        <br />
        <div>
          <Card className="card-hidden-behind">
            <Image
              src={BaseImageWithName}
              alt="Knots and Knowledge on top of a wood image"
              fluid
            />
          </Card>
        </div>
      </div>
    </>
  );
};

export default About;
