// ui_frontend/templates/ui/src/buttons/useDayNightTheme.js
import React, { useState, useEffect, useContext } from 'react';

const ThemeDayNightContext = React.createContext({
  isDayNight: false,
  toggleDayNightTheme: () => {},
});

// Can only be used within the ThemeDayNightContext provider
const useDayNightTheme = () => {
  const context = useContext(ThemeDayNightContext);
  if (!context) {
    throw new Error(
      'useDayNightTheme must be used within ThemeDayNightProvider'
    );
  }
  return context;
};

// A custom hook to add ".daynight" class to the the body
// element if the persisted mode on localStorage is daynight.
const useDayNightThemeEffect = () => {
  const [themeDayNightState, setThemeDayNightState] = useState({
    isDayNight: false,
    hasDayNightThemeLoaded: false,
  });
  useEffect(() => {
    const lsDayNight = localStorage.getItem('isDayNight') === 'true';
    if (lsDayNight) {
      document.querySelector('body').classList.add('daynight');
    }
    setThemeDayNightState({
      ...themeDayNightState,
      isDayNight: lsDayNight,
      hasDayNightThemeLoaded: true,
    });
  }, []);
  return { themeDayNightState, setThemeDayNightState };
};

const ThemeDayNightProvider = ({ children }) => {
  const { themeDayNightState, setThemeDayNightState } = useDayNightThemeEffect();
  // Render <div /> if the mode is not loaded yet
  // to avoid rendering in the regular setting by default
  if (!themeDayNightState.hasDayNightThemeLoaded) return <div />;
  // Add or remove the daynight class from the body element
  // when a user toggles the switch
  const toggleDayNightTheme = () => {
    const isDayNight = !themeDayNightState.isDayNight;
    localStorage.setItem('isDayNight', JSON.stringify(isDayNight));
    const bodyEl = document.querySelector('body');
    isDayNight
      ? bodyEl.classList.add('daynight')
      : bodyEl.classList.remove('daynight')
    setThemeDayNightState({ ...themeDayNightState, isDayNight });
  };

  return (
    <ThemeDayNightContext.Provider
      value={{
        isDayNight: themeDayNightState.isDayNight,
        toggleDayNightTheme,
      }}
    >
      {children}
    </ThemeDayNightContext.Provider>
  );
};

export { ThemeDayNightProvider, useDayNightTheme };
