// ui_frontend/templates/ui/src/routes/Posts.jsx
import { useState, useEffect } from 'react';
import {
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import MiniSearch from 'minisearch';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import { getPosts } from '../datacalls/getPosts';
import Spinner from '../components/Spinner';
import Title from '../components/Title';
import SearchContainer from '../components/Search';
import PostCard from '../posts/PostCard';
import ProjectText from '../sitecontents/ProjectText';

import BaseImageWithName from '../assets/BaseImageWithName.jpg';

// postId is set to post.slug pretty directly here.
export default function Posts(props) {
  const { status, data, error } = useQuery({
    queryKey: ['posts'],
    queryFn: async () => getPosts
  });
  // if (status === 'pending') {
  //   return <div align="center"><Spinner /></div>;
  // }
  // if (status === 'error') {
  //   return <p>Error from the all Posts page: {error.message}</p>;
  // }
  let posts = data;
  // console.log("The following should be posts on the posts page", posts);
  // console.log("Next line to see if it is an array")
  return (
    <>
      <Title
        title="Knots & Knowledge | Post List"
        description="Index page for all posts on the Knots and Knowledge website. Posts are about learning new creative artistic hobbies like woodworking. It contains wood craft resources, art supplies, and more."
        keywords="woodworking, woodworking posts, crafting posts, crafts, crafty, craft information, craft lessons, woodworking lessons"
      />
      <div className="extra-space-top-only">
        <div className="content-wrapper">
          <br />
          <h1 align="center">Posts</h1>
          <ProjectText />
          <Row>
            <Col>
              <h3>Or search here . . . </h3>
              <p>
                The search requires at least 3 letters to begin to find matches.
                Any matches will show up as a link with the post's title, it won't
                show any snippets of the posts, though it has searched through all
                of the text in the posts. 
              </p>
            </Col>
            <SearchContainer />
          </Row>
          <br />
          <>
          <Row className="card-container">
            {status === 'pending' ? (
              <Spinner />
            ) : status === 'success' ? (
              posts.map((post) => {
                const postId = post.slug_post;
                return (
                  <Col key={post.pkid} xs={12} sm={6} md={4} lg={3}>
                    <PostCard post={post} />
                  </Col>
                );
              })
            ) : status === 'error' ? (
              <p>Error from the all Posts page: {error.message}</p>
            ) : null}
            </Row>
          </>
        </div>

        <br />
        <div>
          <Card className="card-hidden-behind">
            <Image
              src={BaseImageWithName}
              alt="Knots and Knowledge on top of a wood image"
              fluid
            />
          </Card>
        </div>
      </div>
    </>
  );
}
