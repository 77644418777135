// ui_frontend/templates/ui/src/siteContents/ArticleEnd.jsx
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { getSiteContents, getSiteContent } from '../datacalls/getSiteContent';
import Spinner from '../components/Spinner';
// The following is important information from site contents in relation to
// the contents of this function.
// ARTICLE_END = 'article-end'

export default function ArticleEnd(props) {
  const queryClient = useQueryClient();
  const [selectedSiteContent, setSelectedSiteContent] = useState();
  // const { isLoading, isError, data, error } = useQuery(['article-end', selectedSiteContent], () =>
  //   getSiteContent('article-end'), // using the api slug here
  // );
  const { isPending, isError, data, error } = useQuery({
    queryKey: ['article-end', { selectedSiteContent }],
    queryFn: () => getSiteContent('article-end'), // using the api slug here
  });
  if (isPending) {
    return <div align="center"><Spinner /></div>;
  }
  if (isError) {
    return <p>Error from the article end section in site contents: {error.message}</p>;
  }
  let articleend = data;

  return (
    <>
      <ReactMarkdown>{articleend.description}</ReactMarkdown>
    </>
  );
}
