// ui_frontend/templates/ui/src/routes/Fortune.jsx
import FortuneCard from '../fortunes/FortuneCard';
import Spinner from '../components/Spinner';
import Title from '../components/Title';
import { getFortunes } from '../datacalls/getFortunes';

// <div className="page-container fortune-override top-only">
//   <div className="fortune-wrapper">
//     <FortuneCard />
//   </div>
// </div>

export default function Fortune(props) {

  return (
    <>
      <Title
        title="Knots & Knowledge | Fortune"
        description="Getting a fortune from a funny and fortunate cuttlefish"
        keywords="fortunate cuttlefish, little cthulhu, cuttlefish, funny fortune"
      />
        <div className="page-container fortune-override top-only">
          <FortuneCard />
        </div>
    </>
  );
}
