// ui_frontend/templates/ui/src/routes/Post.jsx
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLoaderData } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Figure from "react-bootstrap/Figure";
import { useQuery, useQueries, useQueryClient } from "@tanstack/react-query";
import { BiConfused, BiBot } from "react-icons/bi";
import { GiLoveMystery, GiOwl, GiHelp, GiWyvern } from "react-icons/gi";
import { TbSwords } from "react-icons/tb";
import { getPosts, getPost, getRecommends } from "../datacalls/getPosts";
import { isPostUrl, isRecommend } from "../datacalls/urlLoaders";
import Spinner from "../components/Spinner";
import OneRec from "../posts/OneRec";
import PartOnePost from "../posts/PartOnePost";
import PartTwoPost from "../posts/PartTwoPost";
import ArticleEnd from "../sitecontents/ArticleEnd";

import LearnNew from "../assets/LearnNew.jpg";
import BaseImage1 from "../assets/BaseImage1.jpg";
import ColorfulGlass from "../assets/ColorfulGlass.jpg";
import StainedGlass from "../assets/StainedGlass.jpg";

export default function Post(props) {
  // useParams is what attaches this to the correct page.
  const { postId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const checkPostUrl = isPostUrl(postId);
  const checkRecUrl = isRecommend(postId);

  const {
    isPending: postLoading,
    isError: postError,
    data: post,
    error: postErrorDetails,
  } = useQuery({
    queryKey: ['post', { postId }],
    queryFn: () => getPost(postId),
  });
  // const { isLoading, isError, data, error } = useQuery({
  //   queryKey: ['resource-text', { selectedSiteContent }],
  //   queryFn: () => getSiteContent('resource-text'), // using the api slug here
  // });
  const {
    isPending: recLoading,
    isError: recError,
    status,
    data: recommends,
    error: recErrorDetails,
  } = useQuery({
    queryKey: ['recommends', { postId }],
    queryFn: () => getRecommends(postId),
  });

  if (checkPostUrl == false) {
    return (
      <>
        <div className="extra-space-top-only">
          <div className="content-wrapper">
            <Card className="card-static-one">
              <Card.Title>
                <h1>Oh No! An Error has occured . . .</h1>
                <p>This is unexpected. This url does not exist.</p>
              </Card.Title>
              <Card.Body>
                <Container align="center">
                  <Row>
                    <Col>
                      <GiLoveMystery className="icon-two" />
                      <GiHelp className="icon-three" />
                      <GiWyvern className="icon-two" />
                    </Col>
                  </Row>
                </Container>
                <br />
                <hr />
                <br />
                <Row align="center">
                  <Col>
                    <Link to="/home" className="link-router">
                      <button className="two-button">
                        <span className="text">To Get to the Home Page</span>
                        <span>Click Here</span>
                      </button>
                    </Link>
                  </Col>
                  <Col>
                    <Link to="/posts" className="link-router">
                      <button className="button-icon">
                        <div className="icon-seven">
                          <IoConstruct />
                        </div>
                        <div className="cube">
                          <span className="side front">hover me</span>
                          <span className="side top">check it on github</span>
                        </div>
                      </button>
                    </Link>
                  </Col>
                </Row>
                <br />
                <br />
                <Row align="center">
                  <i>Error Message: This post url doesn't exist</i>
                </Row>
              </Card.Body>
            </Card>
          </div>
          <br />
          <div>
            <Card className="card-hidden-behind">
              <Image
                src={ColorfulGlass}
                alt="Interstial Stained Glass Image"
                fluid
              />
            </Card>
          </div>
        </div>
      </>
    );
  } else if (checkRecUrl == true && checkPostUrl == true) {
    if (postLoading) {
      return (
        <div align="center">
          <Spinner />
        </div>
      );
    }
    if (recLoading) {
      return (
        <div align="center">
          <Spinner />
        </div>
      );
    }
    return (
      <>
        <div className="extra-space-top-only">
          <div className="content-wrapper">
            <PartOnePost post={post} />
            <>
            {status === 'pending' ? (
              <Spinner />
            ) : status === 'success' ? (
              recommends.map((recommend) => {
                if (post.slug_post === recommend.slug_post_rec) {
                  return (
                    <Row key={recommend.pkid} className="extra-space">
                      <OneRec recommend={recommend} />
                    </Row>
                  );
                }
              })
            ) : status === 'error' ? (
              <p>Error from the all Posts page: {error.message}</p>
            ) : null}
            </>
            <PartTwoPost post={post} />
            <br />
            <ArticleEnd />
          </div>
          <br />
          <div>
            <Card className="card-hidden-behind">
              <Image
                src={BaseImage1}
                alt="Wood with a pretty design on top"
                fluid
              />
            </Card>
          </div>
        </div>
      </>
    );
  } else if (checkPostUrl == true && checkRecUrl == false) {
    if (postLoading) {
      return (
        <div align="center">
          <Spinner />
        </div>
      );
    }
    return (
      <>
        <div className="extra-space-top-only">
          <div className="content-wrapper">
            <PartOnePost post={post} />
            <PartTwoPost post={post} />
            <br />
            <ArticleEnd />
          </div>
          <br />
          <div>
            <Card className="card-hidden-behind">
              <Image
                src={StainedGlass}
                alt="Stained glass like on the front page"
                fluid
              />
            </Card>
          </div>
        </div>
      </>
    );
  }
  // This point should not be reached under normal circumstances
  return null;
}

// {recommends.map((recommend) => {
//   if (post.slug_post === recommend.slug_post_rec) {
//     return (
//       <Row key={recommend.pkid} className="extra-space">
//         <OneRec recommend={recommend} />
//       </Row>
//     );
//   }
// })}

// console.log("the post id is here ", postId);
// console.log("check what checkposturl returns ", checkPostUrl);
// console.log("check what checkRecUrl returns ", checkRecUrl);
// const {
//   isPending: postLoading,
//   isError: postError,
//   data: post,
//   error: postErrorDetails,
// } = useQuery({
//   queryKey: ['post', { postId }],
//   queryFn: async () => {
//     const post = await getPost(postId);
//     if (checkPostUrl == true) {
//       return post;
//     }
//   }
// });

// const {
//   isPending: recLoading,
//   isError: recError,
//   data: recommends,
//   error: recErrorDetails,
// } = useQuery({
//   queryKey: ['recommends', { postId }],
//   queryFunction: async() => {
//     const recommends = await getRecommends(postId);
//     if (checkRecUrl == true) {
//       return recommends;
//     }
//   }
// });

// if (checkRecUrl) {
//   // Fetch recommendations only if checkRecUrl is true
//   const {
//     isLoading: recLoading,
//     isError: recError,
//     data: recommends,
//     error: recErrorDetails,
//   } = useQuery(['recommends', postId], () => getRecommends(postId));
//
//   // Render loading spinner while fetching recommendations
//   if (recLoading) {
//     return <div align="center"><Spinner /></div>;
//   }
//
//   // Render error message if there is an issue fetching recommendations
//   if (recError) {
//     return <p>Error from recommends: {recErrorDetails.message}</p>;
//   }
//
//   // Render content if checkRecUrl is true and recommends data is available
//   if (checkRecUrl && recommends) {
//     console.log("Recommends data:", recommends);
//     return (
//          <>
//            <div className="section content-wrapper">
//              <div className="container-center content-wrapper">
//                <h1>Post True</h1>
//                <h1>Rec True</h1>
//                <PartOnePost post={post} />
//                <PartTwoPost post={post} />
//              </div>
//            </div>
//          </>
//        );
//      }
//    }

// Normally the title is added at the top of a page like this, it is currently
// in Parts of Posts Pages instead as it works better there in this case.

// <PartOnePost post={post} />
// <PartTwoPost post={post} />

// The postId is here is attached via useParams. It is set in links and items as well.
// export default function Post(props) {
//   const { postId } = useParams();
//   const checkPostUrl = isPostUrl(postId);
//   const checkRecUrl = isRecommend(postId);
//
//   console.log("the post id is here ", postId);
//   console.log("1");
//   console.log("check what checkposturl returns ", checkPostUrl);
//   console.log("2");
//   console.log("check what checkRecUrl returns ", checkRecUrl);
//   console.log("3");
//   console.log("4");
//
//   // Check if postId is invalid and return an error message
//   if (!checkPostUrl) {
//     console.log("Invalid post ID:", postId);
//     return (
//       <>
//         <div className="section content-wrapper">
//           <div className="container-center content-wrapper">
//             <Row>
//               <h1 className="error-title">Oh No! An Error has occurred...</h1>
//               <p className="error-title">Invalid post ID.</p>
//             </Row>
//           </div>
//         </div>
//       </>
//     );
//   }

//
//   // Render content based on the fetched data
//   console.log("Post data:", post);
//   console.log("Recommends data:", recommends);
//
//   return (
//     <>
//       <div className="section content-wrapper">
//         <div className="container-center content-wrapper">
//           <h1>Post True</h1>
//           {checkRecUrl ? (
//             <>
//               <h1>Rec True</h1>
//               {recommends ? (
//                 <>
//                   <PartOnePost post={post} />
//                   <PartTwoPost post={post} />
//                 </>
//               ) : (
//                 <div align="center"><Spinner /></div>
//               )}
//             </>
//           ) : (
//             <h1>Rec False</h1>
//           )}
//         </div>
//       </div>
//     </>
//   );
// }

// if ((checkPostUrl == false) && (checkRecUrl == false)) {
//   return (
//     <>
//       <h1>Does not exist</h1>
//       <h1>URL does not exist</h1>
//       <h1>This is just an else</h1>
//     </>
//   );
// }

// if (!checkPostUrls) {
//   return (
//     <>
//     <div className="section content-wrapper">
//     <div className="container-center content-wrapper">
//       <Row>
//         <h1 className="error-title">Oh No! An Error has occurred...</h1>
//         <p className="error-title">Invalid post ID.</p>
//       </Row>
//
//       <Container align="center">
//         <Row>
//           <Col>
//             <BiBot className="icon-two" />
//             <TbSwords className="icon-three" />
//             <BiConfused className="icon-two" />
//           </Col>
//         </Row>
//       </Container>
//       <br />
//       <hr />
//       <br />
//       <Row align="center">
//         <Col>
//           <Link to="/home">
//             <button className="btn-black-blue">Home Page</button>
//           </Link>
//         </Col>
//         <Col>
//           <Link to="/posts">
//             <button className="btn-black-blue">Post List</button>
//           </Link>
//         </Col>
//       </Row>
//     </div>
//   </div>
// </>
// );
// }
// } else if (checkPostUrl == false) {
//   console.log("check post url is false", checkPostUrl);
//   console.log("check if rec url is false ", checkRecUrl);
//   return (
//     <>
//       <div className="section content-wrapper">
//         <div className="container-center content-wrapper">
//               <Row>
//                 <h1 className="error-title">Oh No! An Error has occured . . .</h1>
//                 <p className="error-title">This is unexpected. This url does not exist.</p>
//               </Row>
//
//               <Container align="center">
//                 <Row>
//                   <Col>
//                     <BiBot className="icon-two" />
//                     <TbSwords className="icon-three" />
//                     <BiConfused className="icon-two" />
//                   </Col>
//                 </Row>
//               </Container>
//               <br />
//               <hr />
//               <br />
//               <Row align="center">
//                 <Col>
//                   <Link to="/home">
//                     <button className="btn-black-blue">Home Page</button>
//                   </Link>
//                 </Col>
//                 <Col>
//                   <Link to="/posts">
//                     <button className="btn-black-blue">Post List</button>
//                   </Link>
//                 </Col>
//               </Row>
//         </div>
//       </div>
//     </>
//   );
// }
// } else if ((checkPostUrl == true) && (checkRecUrl == false)) {
//   console.log("5");
//   const {
//     isLoading: postLoading, isError: postError, data: post, error: postErrorDetails
//   } = useQuery(['post', postId], () =>
//     getPost(postId),
//   );
//   if (postLoading) {
//     return <div align="center"><Spinner /></div>;
//   }
//   if (postError) {
//     return <p>Error from a post page: {postErrorDetails.message}</p>;
//   }
//     return (
//       <>
//         <div className="section content-wrapper">
//           <div className="container-center content-wrapper">
//             <h1>Post True</h1>
//             <h1>Rec False</h1>
//
//           </div>
//         </div>
//       </>
//     );
// {checkRecUrl ? (
//   const { status, error, data } = useQuery(['recommends', postId], () =>
//     getRecommends(postId),
//   );
//   let recommends = data;
//   console.log("Should be recommends", recommends);
//   // Rendered if condition is true
//   <>
//     {recommends.map((recommend) => (
//       <OneRec recommend={recommend} />
//     ))}
//   </>
// ) : (
//   // Rendered if condition is false
//   <span></span>
// )}
// useEffect(() => {
//   if (checkPostUrl == true) {
//     const {
//       isLoading: postLoading, isError: postError, data: postData, error: postErrorDetails
//     } = useQuery(['post', postId], () =>
//       getPost(postId),
//     );
//     if (postLoading) {
//       return <div align="center"><Spinner /></div>;
//     }
//     if (postError) {
//       return <p>Error from a post page: {postErrorDetails.message}</p>;
//     }
//     let post = postData;
//   }
// }, [checkPostUrl, postId]);
