// ui_frontend/templates/ui/src/routes/FortuneTeller.jsx
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Figure from 'react-bootstrap/Figure';
import Row from 'react-bootstrap/Row';
import {
  FaFeatherAlt,
  FaGithub,
  FaPaperPlane,
  FaEye,
  FaExternalLinkAlt
} from 'react-icons/fa';
import { AiOutlineShop } from 'react-icons/ai';

import Title from '../components/Title';
import FortuneForm from '../fortunes/FortuneForm';

import FirstPageCuttleFishEye from '../assets/FirstPageCuttlefishEye.jpg';


const FortuneTeller = () => {

  return (
    <>
    <Title
      title="Knots & Knowledge | Get a Fortune"
      description="Get an absolutely ridiculous and possibly, well probably, an unhinged fortune"
      keywords="fortunate cuttlefish, little cthulhu, fortune teller, Django React, craft blog, crafty blog, woodworking"
    />
      <div className="fortune-override page-container top-only">
        <br />
        <Container>
        <Container>
          <h3>Want to have some silly fun? You can get a ridiculous fortune from
            a cuttlefish below. This was from a past site I made. This page and
            the page with funny fortunes do not have the day/night site change.
          </h3>
        </Container>
        </Container>
        <br />
        <br />
        <Container>
          <Container>
          <Row>
            <Col xs={12} sm={5} className="element-wrapper">
              <Figure>
                <Card className="fig-back">
                  <Figure.Image
                    width={300}
                    height={400}
                    alt="A Cuttlefish eye close up from the side"
                    src={FirstPageCuttleFishEye}
                  />
                  <Figure.Caption className="figure-caption-custom">
                    It's me, the Fortune Giver
                  </Figure.Caption>
                </Card>
              </Figure>
            </Col>

            <Col xs={12} sm={7}>
              <div className="title-one">
                Hello I am the Underwater Fortune Giver. Would you like some
                guidance by way of a fortune . . .{' '}
              </div>
            </Col>
          </Row>
          </Container>

          <Container>
          <Row>
            <p style={{ color: '#e3dffb' }}>
              . . . Are you feeling brave? If you would like your fortune,
              please enter your name or a fake name of your choosing (letters,
              spaces, and hyphens only please) with at least 2 letters and less,
              than 30 letters, and choose the CURRENT color of your aura.
            </p>
            <br />
          </Row>
          </Container>

          <Row>
            <Container className="container-center">
              <h3 className="special-title">THEN PUSH THE BUTTON!</h3>
            </Container>
          </Row>
          <Row>
            <Container className="container-center">
              <FortuneForm />
            </Container>
          </Row>
          <br />
          <p>
          Cuttlefish are amazing creatures, though only some can tell your fortune!
          There are many places to find out more about them.
          Nova has a cuttlefish specific page, and there are many cool cuttlefish videos on
          YouTube and other places.
          </p>
          <br />
          <Row>
            <a
              href="https://www.pbs.org/wgbh/nova/camo/anat-nf.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="nova-button">
                Nova Cuttlefish Page <FaExternalLinkAlt />
              </button>
            </a>
          </Row>
        </Container>
        <br />
        <br />
      </div>
    </>
  );
};

export default FortuneTeller;
