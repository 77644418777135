// ui_frontend/templates/ui/src/routes/Home.jsx
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Figure from 'react-bootstrap/Figure';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import {
  FaExternalLinkAlt
} from 'react-icons/fa';

import CraftyCraftsy from '../assets/CraftyCraftsy.jpg';
import CraftyCreativebug from '../assets/CraftyCreativebug.jpg';
import CraftyVirtual from '../assets/CraftyVirtual.jpg';
import WoodInstructables from '../assets/WoodInstructables.jpg';
import WoodWeekend from '../assets/WoodWeekend.jpg';
import WoodworkingtheCompleteManual from '../assets/WoodworkingtheCompleteManual.jpg';


const Classes = () => {

  return (
    <>
      <div className="content-wrapper">
        <h1 align="center">Woodworking and Crafting Lessons</h1>
      </div>

      <div className="content-wrapper">
        <p>
          This site is here to connect you with lessons, supplies, and the information
          needed to take up new crafting methods like woodworking, and also to connect
          you what goes with it, like art to go with those items you make with your
          woodworking skills from reclaimed wood. There are also recommendations
          for educational toys, kits, and some regular toys as well.
        </p>
        <p>
          While some of the following links are also in a few of this sites posts, I wanted to
          directly link some comprehensive digital classes for learning woodworking, crafting,
          and drawing online. This site is all about learning new creative skills after all!
          One of the best things about all of these sites is that they are
          not covered in ads. Many sites are so ad covered these days that they can feel
          unusable. The following sites are a breath of fresh air and have quite a lot of
          content available, especially on the drawing and crafting sites.
        </p>
      </div>
      <br />

      <Card className="card-hidden-behind">
        <Image
          src={WoodInstructables}
          alt="Instructables woodworking lessons that are free"
          fluid
        />
      </Card>
      <br />

      <div className="content-wrapper">
        <p>
          Instructables has free woodworking lessons and free woodworking project plans.
          If you are interested in trying out woodworking but not sure if it is for you
          the free instructions from Instructables are a great place to start. While they
          don't have an extensive free set of lessons, they have a enough for you to get
          an idea of if you'll enjoy woodworking.
        </p>
      <Row align="center">
        <Col>
        <a
          href="https://www.instructables.com/Woodworking-Class/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="btn-move-one">
            Instructables Woodworking Lessons <FaExternalLinkAlt />
          </button>
        </a>
        </Col>
        <Col>
        <a
          href="https://www.instructables.com/workshop/woodworking/projects/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="btn-move-one">
            Instructables Woodworking Plans <FaExternalLinkAlt />
          </button>
        </a>
        </Col>
      </Row>
      </div>
      <br />
      <br />

      <Card className="card-hidden-behind">
        <Image
          src={CraftyCreativebug}
          alt="Creativebug has all kinds of digital crafting lessons"
          fluid
        />
      </Card>
      <br />

      <div className="content-wrapper">
        <p>
          Creativebug has all kinds of crafting lessons. They are not free but they
          do often have a free trial and their subscription is also a fairly low cost
          one. A subscription with them gives you access to many different crafting
          techniques and lessons in the categories of drawing, sewing, quilting,
          papercrafts, knitting, crochet, food and drink, jewelry, and holiday and party.
          There is also a section with crafts for kids. You also get access to a pattern
          library and daily practice plans.
        </p>
        <p>
          Creativebug is a great place to try out new crafting techniques to see what you like
          and since they often have a free trial period, you can figure out if their digital
          lessons are right for you for free.
        </p>
      <Row align="center">
        <Col>
        <a
          href="https://shareasale.com/r.cfm?b=404942&u=3901372&m=42943&urllink=&afftrack="
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="btn-move-two">
            Creativebug <FaExternalLinkAlt />
          </button>
        </a>
        </Col>
      </Row>
      </div>
      <br />
      <br />

      <Card className="card-hidden-behind">
        <Image
          src={CraftyVirtual}
          alt="The Virtual Instructor is a site full of drawing and painting lessons"
          fluid
        />
      </Card>
      <br />

      <div className="content-wrapper">
        <p>
          The Virtual Instructor site contains many how to draw, paint, use color pencils,
          use pastels, etc. lessons. Drawing can be is difficult and takes a lot of practice.
          These lessons are extremely helpful and will get you there sooner. Lessons from the Virtual Instructor
          can be accessed with a subscription. I've used these lessons to learn blendable color
          pencil techniques. I loved what I was able to make.
        </p>
        <p>
          All the lessons from the Virtual Instructor can be accessed for a little under
          $100 a year, and that is honestly fairly cheap given that it's an absolute treasure trove.
        </p>
      <Row align="center">
        <Col>
        <a
          href="https://thevirtualinstructor.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="btn-move-one">
            The Virtual Instructor <FaExternalLinkAlt />
          </button>
        </a>
        </Col>
      </Row>
      </div>
      <br />
      <br />

      <Card className="card-hidden-behind">
        <Image
          src={CraftyCraftsy}
          alt="Craftsy is a site full of crafting lessons"
          fluid
        />
      </Card>
      <br />

      <div className="content-wrapper">
        <p>
          Craftsy is a site where you can buy or subscribe to different courses in quilting, sewing,
          knitting, cake making and decorating, and cooking. They have more than one
          way to purchase content, and you are allowed to share your subscription with up to
          three other people as well.
        </p>
        <p>
          You can buy class by class without a subscription and you can even access
          a few free lessons without a subscription. You can also get a subscription
          to access many more classes instead of buying them one by one. It usually costs
          a little over $100 for the year, but they have deals for their subscriptions at times
          (I've seen it as low as $1.49 a year) so if you're looking to learn new crafting techniques,
          they are a great place to start.
        </p>
      <Row align="center">
        <Col>
        <a
          href="https://shareasale.com/r.cfm?b=1625237&u=3901372&m=101492&urllink=&afftrack="
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="btn-move-two">
            Craftsy <FaExternalLinkAlt />
          </button>
        </a>
        </Col>
      </Row>
      </div>
      <br />
      <br />

      <Card className="card-hidden-behind">
        <Image
          src={WoodWeekend}
          alt="The Weekend Woodworker has paid lessons that are comprehensive for beginner woodworkers"
          fluid
        />
      </Card>
      <br />

      <div className="content-wrapper">
        <p>
          The Weekend Woodworker is for beginners and it is comprehensive. It does not
          contain free content, and though it looks like an affiliate style site that
          has a spammy upselling vibe to it, it isn't. It has a lot of great reviews in legitimate
          places other than on their own site, and I am not affiliated with them. I am not sure
          if others have affiliate links with them.
        </p>
        <p>
          The Weekend Woodworker is for beginners and contains quite a lot, like what specific
          tools are called, used for, and how to use them along with beginner lessons and
          projects. The makers of this class sell other woodworking classes as well and you
          might enjoy them once you have gotten past the beginner stage.
        </p>
        <p>
          These courses are not free. The Weekend Woodworker costs $147 and the lessons are
          structured so you can get through them over 6 weekends. It is not a subscription.
          The class is a (rare these days) buy once style of class.
        </p>
      <Row align="center">
        <Col>
        <a
          href="https://theweekendwoodworker.com/signup/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="btn-move-one">
            The Weekend Woodworker <FaExternalLinkAlt />
          </button>
        </a>
        </Col>
        <Col>
        <a
          href="https://theweekendwoodworker.com/courses/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="btn-move-one">
            The Other Weekend Woodworker Courses <FaExternalLinkAlt />
          </button>
        </a>
        </Col>
      </Row>
      </div>
      <br />
      <br />

        <Container className="container-center-flex">
          <Card style={{ width: '20rem' }} className="card-image-static-two">
            <Row align="center">
              <Col align="center">
                <Image
                  src={WoodworkingtheCompleteManual}
                  alt="Woodworking Manual Cover"
                  align="center"
                  fluid
                />
              </Col>
            </Row>
            <Row className="row-no-margin">
              <p align="center" className="caption">The Woodworking Manual Cover Image</p>
            </Row>
          </Card>
        </Container>
      <br />

      <div className="content-wrapper">
          <p>
            This book is for learning woodworking. I wanted to include this
            book because it has over 800 ratings on Amazon and still has a 4.7
            star rating, which is incredibly high for an item on the site with
            that many ratings. It is available for $14.99 on Kindle and about $36
            in Hardcover format (the hardcover price fluxuates). The tagline is:
          </p>
          <p>
            Get started on your woodwork practice with this ultimate guide to
            essential carpentry skills, techniques, tools, tips, and tricks.
          </p>

      <Row align="center">
        <Col>
        <a
          href="https://amzn.to/4aAWyD7"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="btn-move-two">
            Woodworking Manual: the Kindle Version <FaExternalLinkAlt />
          </button>
        </a>
        </Col>
        <Col>
        <a
          href="https://amzn.to/3yuxOzd"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="btn-move-two">
            Woodworking Manual: the Hardcover Version <FaExternalLinkAlt />
          </button>
        </a>
        </Col>
      </Row>

      </div>

      <br />
      <br />
    </>
  );
};

export default Classes;


// <Col className="element-wrapper">
//
// <Figure align="center">
//   <Card className="front-page-fig-back" align="center">
//     <Figure.Image
//       width={350}
//       alt="Woodworking Manual Cover"
//       src={WoodworkingtheCompleteManual}
//     />
//     <Figure.Caption className="front-page-figure-caption-custom">
//       The Woodworking Manual Cover Image
//     </Figure.Caption>
//   </Card>
// </Figure>
//
// </Col>
