import { useState, useEffect } from 'react';
import { useQueries } from '@tanstack/react-query';
import { getPosts, getAllRecommends } from '../datacalls/getPosts';

const getProcessedData = (postsData, recommendsData) => {
  const searchPosts = postsData || [];
  const searchRecommends = recommendsData || [];

  return searchPosts.map((post) => {
    let modifiedPost = { ...post };

    if (modifiedPost.intro && modifiedPost.outro) {
      modifiedPost.intro += " " + modifiedPost.outro;
      delete modifiedPost.outro;
    }

    searchRecommends.forEach((recommend) => {
      if (recommend.rec_slug === post.slug) {
        if (recommend.rec_desc) {
          if (modifiedPost.intro) {
            modifiedPost.intro += " " + recommend.rec_desc;
          } else {
            modifiedPost.intro = recommend.rec_desc;
          }
        }
      }
    });

    if (modifiedPost.intro) {
      modifiedPost.intro = modifiedPost.intro.replace(/\r\n\r\n/g, " ");
    }

    return {
      id: modifiedPost.id,
      slug: modifiedPost.slug,
      title: modifiedPost.title,
      intro: modifiedPost.intro,
    };
  });
};

const useFetchAndProcessData = () => {
  const [
    {
      data: postsData,
      isLoading: postsLoading,
      error: postsError,
    },
    {
      data: recommendsData,
      isLoading: recommendsLoading,
      error: recommendsError,
    },
  ] = useQueries({
    queries: [
      {
        queryKey: ['posts'],
        queryFn: getPosts,
        select: (posts) =>
          posts.map((post) => ({
            id: post.pkid,
            title: post.title,
            slug: post.slug_post,
            intro: post.post_intro,
            outro: post.post_outro,
          })),
      },
      {
        queryKey: ['allrecommends'],
        queryFn: getAllRecommends,
        select: (allrecommends) =>
          allrecommends.map((recommend) => ({
            rec_name: recommend.recommend_name,
            rec_slug: recommend.slug_post_rec,
            rec_desc: recommend.recommend_description,
          })),
      },
    ],
  });

  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (postsLoading || recommendsLoading) {
      setLoading(true);
    } else if (postsError || recommendsError) {
      setError(postsError || recommendsError);
      setLoading(false);
    } else if (postsData && recommendsData) {
      const processedData = getProcessedData(postsData, recommendsData);
      setDocuments(processedData);
      setLoading(false);
      console.log("Processed Data:", processedData);
    }
  }, [postsLoading, recommendsLoading, postsError, recommendsError, postsData, recommendsData]);

  return { documents, loading, error };
};

export default useFetchAndProcessData;
