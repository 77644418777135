// ui_frontend/templates/ui/src/components/Spinner.jsx
// Loading Spinner made by Made by @gagan-gv on
// https://uiverse.io/detail/gagan-gv/nervous-wasp-20

// The letters kind of bounce up and down and look a little backlit.
import { FaTools } from "react-icons/fa";
import { SiSnapcraft } from "react-icons/si";
import { GiVanillaFlower, GiYarn, GiSewingString } from "react-icons/gi";

const Spinner = () => {
  return (
    <div className="loading-spinner">
      <span><SiSnapcraft /></span>
      <span>{'  '}</span>
      <span><GiYarn /></span>
      <span>{'  '}</span>
      <span><GiSewingString /></span>
      <span>{'  '}</span>
      <span><FaTools /></span>
    </div>
  );
};

export default Spinner;
