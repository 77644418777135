// ui_frontend/templates/ui/src/routes/Root.jsx
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Outlet,
  Link,
  useNavigation,
  ScrollRestoration,
} from 'react-router-dom';
import { useQuery, useIsFetching, useQueries } from '@tanstack/react-query';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadBasic } from '@tsparticles/basic';

import { TiThMenuOutline } from "react-icons/ti";

import ToggleSwitchDayNight from '../buttons/ToggleSwitchDayNight';
import { useDayNightTheme } from '../buttons/useDayNightTheme';

import { getPosts, getAllRecommends } from '../datacalls/getPosts';
import Footer from '../components/Footer';
import Spinner from '../components/Spinner';
import navicon from '../assets/navicon.png';

// I made the top navbar part of the root element because it works faster this
// way than if I have it refactored as part of another element.

export default function Root() {

  const navigation = useNavigation();
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };
  const options = useMemo(
    () => ({
      background: {
        enable: false,
      },
      fpsLimit: 120,
      particles: {
        color: {
          value: ["#BE81F7", "#D8F6CE", "#f0e3a3", "#9cd8f2"],
        },
        move: {
          direction: "none",
          enable: true,
          outModes: {
            default: "bounce",
          },
          random: true,
          speed: 0.3, // maybe change back to 0.2
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 45,
        },
        opacity: {
          value: 0.3,
        },
        shape: {
          type: "circle",
        },
        size: {
          value: { min: 1, max: 4 }, // changed 3 to 5 change back
        },
      },
      detectRetina: true,
    }),
    [],
  );

  const [postsQuery, recommendsQuery] = useQueries({
    queries: [
      {
        queryKey: ['posts'],
        queryFn: getPosts
      },
      {
        queryKey: ['allrecommends'],
        queryFn: getAllRecommends
      },
    ],
  });
  if (postsQuery.isPending) return <div align="center"><Spinner /></div>;
  if (recommendsQuery.isPending) return <div align="center"><Spinner /></div>;

  if (postsQuery.error)
    return 'An error has occurred in posts: ' + postsQuery.error.message;

  if (recommendsQuery.error)
    return 'An error has occurred in posts: ' + recommendsQuery.error.message;

  // The day night toggle hook
  const { isDayNight, toggleDayNightTheme } = useDayNightTheme();

  return (
    <>
      <Navbar collapseOnSelect expand="lg" fixed="top" className="navbar-top">
        <Container fluid>
          <Navbar.Brand href="/home">
            <img
              alt="Knots and Knowledge Navicon Image"
              src={navicon}
              width="31"
              height="31"
              className="d-inline-block align-bottom"
            />{'  '}
              Knots & Knowledge
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="offcanvasNavbar-expand-md"
          >
            <span><TiThMenuOutline className="toggle-icon" /></span>
          </Navbar.Toggle>
          <Navbar.Collapse className="justify-content-end">
            <Nav>
              <Nav.Item>
                <Nav.Link eventKey="1" href="/home">Home</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="2" href="/about">About</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="3" href="/posts">Posts</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="4" href="/contact">Contact</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="5" href="/resources">Resources</Nav.Link>
              </Nav.Item>
              {'  '}<div className="my-span">
              <ToggleSwitchDayNight
                isDayNight={isDayNight}
                onChange={toggleDayNightTheme}
              />
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div>
        <Particles id="tsparticles" particlesLoaded={particlesLoaded} options={options} />
        <Outlet />
      </div>
      <Footer />
    </>
  );
}

// const [init, setInit] = useState(false);
//
// useEffect(() => {
//   initParticlesEngine(async (engine) => {
//     // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
//     // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
//     // starting from v2 you can add only the features you need reducing the bundle size
//     await loadBasic(engine);
//   }).then(() => {
//     setInit(true);
//   });
// }, []);
//
// const particlesLoaded = (container) => {
//   console.log(container);
// };
//
// const options = useMemo(
//   () => ({
//     fpsLimit: 120,
//     particles: {
//       color: {
//         value: ["#BE81F7", "#D8F6CE", "#f0e3a3", "#9cd8f2"],
//       },
//       move: {
//         direction: "none",
//         enable: true,
//         outModes: {
//           default: "bounce",
//         },
//         random: false,
//         speed: 0.3, // maybe change back to 0.2
//         straight: false,
//       },
//       number: {
//         density: {
//           enable: true,
//         },
//         value: 45,
//       },
//       opacity: {
//         value: 0.2,
//       },
//       shape: {
//         type: "circle",
//       },
//       size: {
//         value: { min: 1, max: 4 }, // changed 3 to 5 change back
//       },
//     }
//   }),
//   [],
// );


// { init && <Particles id="tsparticles" options={options} particlesLoaded={particlesLoaded} /> }
// import Particles from "react-particles";
// import type { Engine } from "@tsparticles/engine";
// import { loadSlim } from "@tsparticles/slim";
// const options = useMemo(
//   () => ({
//     particles: {
//       number: {
//         value: 50,
//         density: {
//           enable: true,
//           area: 800
//         }
//       },
//       color: {
//         value: ["#BE81F7", "#D8F6CE", "#f0e3a3", "#9cd8f2"]
//       },
//       shape: {
//         type: "circle",
//         stroke: {
//           width: 0,
//           color: "#000000"
//         },
//         polygon: {
//           nb_sides: 5
//         }
//       },
//       opacity: {
//         value: 0.2,
//         random: false
//       },
//       size: {
//         value: { min: 1, max: 3 }
//       },
//       move: {
//         enable: true,
//         speed: 0.15,
//         direction: "none",
//         random: false,
//         straight: false,
//         outModes: "out"
//       }
//     }
//   }),
//   [],
// );

//   const particlesInit = useCallback(async (engine) => {
//     await loadFull(engine);
//   }, []);
//
//   const particlesLoaded = (container) => {
//     console.log(container);
//   };
  // { init && <Particles options={options} particlesLoaded={particlesLoaded} />}
  // <Particles options={options} init={particlesInit} />
  // <Particles options={options} particlesLoaded={particlesLoaded} />

// const [ init, setInit ] = useState(false);
// // this should be run only once per application lifetime
// useEffect(() => {
//   initParticlesEngine(async (engine) => {
//       // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
//       // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
//       // starting from v2 you can add only the features you need reducing the bundle size
//       await loadFull(engine);
//   }).then(() => {
//       setInit(true);
//   });
// }, []);
//
// const options = {
//     particles: {
//       number: {
//         value: 50,
//         density: {
//           enable: true,
//           area: 800
//         }
//       },
//       color: {
//         value: ["#BE81F7", "#D8F6CE", "#f0e3a3", "#9cd8f2"]
//       },
//       shape: {
//         type: "circle",
//         stroke: {
//           width: 0,
//           color: "#000000"
//         },
//         polygon: {
//           nb_sides: 5
//         }
//       },
//       opacity: {
//         value: 0.2,
//         random: false
//       },
//       size: {
//         value: { min: 1, max: 3 }
//       },
//       move: {
//         enable: true,
//         speed: 0.15,
//         direction: "none",
//         random: false,
//         straight: false,
//         outModes: "out"
//       }
//     }
//   };
//
//   const particlesInit = useCallback(async (engine) => {
//     await loadFull(engine);
//   }, []);
//   const particlesLoaded = (container) => {
//     console.log(container);
//   };
  // { init && <Particles options={options} particlesLoaded={particlesLoaded} />}
  // <Particles options={options} init={particlesInit} />

// console.log("Because some things are part of the navigation bar, they have to be run here at the start");
// console.log("About to run queries in the root");
