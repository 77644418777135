// ui_frontend/templates/ui/src/posts/PartOnePost.jsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import dateFormat, { masks } from 'dateformat';
import ReactMarkdown from 'react-markdown';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Figure from 'react-bootstrap/Figure';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Title from '../components/Title';
import { FaExternalLinkAlt } from 'react-icons/fa';

// <Image
//   height={250}
//   src={post.post_image}
//   alt={`Photo for ${post.title} Post`}
//   align="center"
//   fluid
// />
// <Card className={`card-image-${post.card_background_type}`}>
// </Card>

const PartOnePost = ({post}) => {

  return (
    <>
      <Title
        title={`Knots & Knowledge | ${post.slug_title}`}
        description={`${post.description_helmet}`}
        keywords={`${post.meta_tags}`}
      />
      <div>
        <Container className="container-center-flex">
        <Card className={`card-image-${post.card_background_type}`}>
          <Row align="center">
            <Col align="center">
              <Image
                src={post.post_image}
                alt={`Photo for ${post.title} Post`}
                align="center"
                fluid
              />
            </Col>
          </Row>
          <Row className="row-no-margin">
            <p align="center" className="caption">{post.post_caption}</p>
          </Row>
        </Card>
        </Container>
        <br />
        <h1 align="center">
          {post.title}
        </h1>
        <ReactMarkdown>
          {post.post_intro}
        </ReactMarkdown>
      </div>
    </>
  );
};

export default PartOnePost;
